.image {
  height: 28px;
  width: 104px;
  margin: 25px 0 40px 45px;
}
.sidebar {
  width: 223px;
  margin: 0 20px;
  padding-left: 10px;
  padding-bottom: 15px;
  border-right: none !important;
  box-shadow: 0 4px 10px rgba(188, 205, 220, 0.2) !important;
  height: auto;
  z-index: 0;
}

.sidebarSm {
  width: 223px;
  padding-left: 15px;
  padding-bottom: 10px;
}

.header {
  border-radius: 15px !important;
}

.item {
  border-radius: 15px !important;
  padding: 10px !important;
  max-height: 59px;
  max-width: 202px;
}

.itemInactive {
  color: #586475;
}

.itemActive {
  background-color: #f6f7fb !important;
  color: #045194 !important;
}

.item:hover {
  color: #045194;
}

.itemIcon {
  color: #586475;
}

.itemIconActive {
  color: #045194;
}
